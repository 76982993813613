import moment from 'moment';
import wallet_account_alipay_fundBatch_detail from "@/lib/data-service/default/wallet_account_alipay_fundBatch_detail";
import wallet_account_alipay_fundBatch_confirm from "@/lib/data-service/default/wallet_account_alipay_fundBatch_confirm";
import wallet_account_alipay_fundBatch_bindDsAccountNo from "@/lib/data-service/default/wallet_account_alipay_fundBatch_bindDsAccountNo";
import wallet_account_alipay_fundBatch_companyAccountList from "@/lib/data-service/default/wallet_account_alipay_fundBatch_companyAccountList";
import wallet_order_alipay_fundBatch_rechargeOrderList from "@/lib/data-service/default/wallet_order_alipay_fundBatch_rechargeOrderList";

export default {
  components: {
  },
  data() {
    const validatorAccountNo = (rule, value, callback) => {
      let re = /(^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$)|(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/
      if (re.test(value)) {
        callback();
      } else {
        callback(new Error('请输入邮箱账号或者手机号'))
      }
    }
    return {
      form: {
        id: '',
        dsAccountNo: '',
      },
      accFormData: {
        pageSize: 100,
        currentPage: 1,
        totalCount: 0
      },
      formData: {
        paymentTimeBegin: '',
        paymentTimeEnd: '',
        successTimeBegin: '',
        successTimeEnd: '',
        orderNo: '',
        orderStatus: '',
        flowNo: '',
        transferNo: '',
        currentPage: 1,
        pageSize: 10,
        totalCount: 0,
      },
      orderStatusList: [
        {
          value: null,
          name: '全部',
        },
        // {
        //   value: 0,
        //   name: '待支付',
        // },
        {
          value: 1,
          name: '支付失败',
        },
        {
          value: 2,
          name: '支付成功',
        },
        // {
        //   value: 3,
        //   name: '已取消',
        // },
        {
          value: 4,
          name: '已入账',
        },
        {
          value: 5,
          name: '入账失败',
        },
      ],
      paymentDate: '',
      successDate: '',
      alipayAccountData: {},
      data_wallet_alipay_companyAccountList: [],
      list: [],
      show_invite_btn_flag: false,
      dialogAccountForm: false,
      rules: {
        dsAccountNo: [
          {required: true, message: '请输入支付宝账号', trigger: 'blur'},
          {validator: validatorAccountNo, trigger: 'blur'},
        ],
      }
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    async __init_list() {
      let res = await wallet_account_alipay_fundBatch_companyAccountList(this.accFormData);
      this.data_wallet_alipay_companyAccountList = res.pageResult.pageData;
    },

    //获取列表
    async getList() {
      let res = await wallet_order_alipay_fundBatch_rechargeOrderList(this.formData);
      this.list = res.pageResult.pageData;
      this.formData.totalCount  = res.pageResult.totalCount;
    },

    reset() {
      this.formData = {
        paymentTimeBegin: '',
        paymentTimeEnd: '',
        successTimeBegin: '',
        successTimeEnd: '',
        orderNo: '',
        orderStatus: '',
        flowNo: '',
        transferNo: '',
        currentPage: 1,
        pageSize: 10,
      };
      this.paymentDate = '';
      this.successDate = '';
      this.getList();
    },

    // 授权详情
    async checkAlipayCompanyAccount() {
      wallet_account_alipay_fundBatch_detail({}).then(res => {
        this.alipayAccountData = res.accountResult
        //是否显示授权按钮
        if (res.accountResult.status === 1) {
          this.show_invite_btn_flag = true;
        } else {
          this.show_invite_btn_flag = false;
        }
      }).catch(err => {
      })
    },
    //授权
    async confirmOpenInvite(val) {
      // 判断授权码是否有效
      wallet_account_alipay_fundBatch_detail({}).then(res => {
        if (res.accountResult.status === 1) {
          this.show_invite_btn_flag = true;
          this.loading = true;
          this.$confirm('是否完成签约授权?', '提示', {
            confirmButtonText: '完成',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$message({type: 'success', message: '操作成功！'})
            this.__init_list()
            this.loading = false;
          }).catch(() => {
            this.loading = false;
          });

          let params = {
            id: val
          }
          wallet_account_alipay_fundBatch_confirm(params).then(res => {
            var neww = window.open('', "_blank");
            if (neww) {
              var doc = neww.document;
              doc.open();
              doc.write(res.formHtml);
              doc.close();
            }
          }).catch(err => {
          })
          this.loading = false;
        } else {
          this.$message.error('操作失败，暂已关闭该功能')
          this.show_invite_btn_flag = false;
        }
      }).catch(err => {
      })
    },

    __el_pagination_size_change_event_handler(val) {
      this.formData.pageSize = val
      this.getList()
    },

    __el_pagination_current_change_event_handler(pCurrentPage) {
      this.formData.currentPage = pCurrentPage
      this.getList()
    },

    // __btn_account_click(val) {
    //   this.form.id = val
    //   this.dialogAccountForm = true
    // },
    // async submitBindDsAccountNo() {
    //   this.$refs["form"].validate(valid => {
    //     if (valid) {
    //       this.loading = true;
    //       wallet_account_alipay_fundBatch_bindDsAccountNo(this.form).then(res => {
    //         this.loading = false;
    //         this.$message({type: 'success', message: '操作成功！'})
    //         this.dialogAccountForm = false
    //         this.__init_list()
    //       }).catch(err => {
    //         this.loading = false;
    //         this.dialogAccountForm = false
    //         $.messager.alert("错误信息", res.msg);
    //       })
    //     }
    //   })
    // },
    //改变支付日期
    changePaymentDate(newVal) {
      if (newVal == null){
        this.formData.paymentTimeBegin = '';
        this.formData.paymentTimeEnd = '';
      } else {
        this.formData.paymentTimeBegin = moment(newVal[0]).format('YYYY-MM-DD') + ' 00:00:00';
        this.formData.paymentTimeEnd = moment(newVal[1]).format('YYYY-MM-DD') + ' 23:59:59';
      }
    },
    //改变入账日期
    changeSuccessDate(newVal) {
      if (newVal == null){
        this.formData.successTimeBegin = '';
        this.formData.successTimeEnd = '';
      } else {
        this.formData.successTimeBegin = moment(newVal[0]).format('YYYY-MM-DD') + ' 00:00:00';
        this.formData.successTimeEnd = moment(newVal[1]).format('YYYY-MM-DD') + ' 23:59:59';
      }
    },
    billEreceiptDownload(val){
      if (val !== null && val !== '') {
        window.open(val);
      } else {
        this.$message({
          type: "warning",
          message: '下载失败，请稍候重试'
        });
      }
    },
  },
  activated() {
    const __this = this;
    __this.__init_list();
    __this.getList();
    __this.checkAlipayCompanyAccount();
  },
  mounted() {
    const __this = this;
  },

  deactivated() {
    // const __this = this;
  },
}
